import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, List, ListItem, ListItemText, Paper, Box } from '@mui/material';
import { getChatHistory, getUserSuggestions, getStreamerEmotes } from '../api/chatApi'; 
import { styled } from '@mui/system';

const FullScreenWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: '#18181B',
    minHeight: '100vh', // Ustawienie minimum wysokości na pełny ekran, zamiast stałej wysokości
    width: '100%', // Pełna szerokość
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0', // Dodatkowa przestrzeń na górze i dole
  }));
  
const StyledContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '800px', // Maksymalna szerokość na większych ekranach
    backgroundColor: '#1e1e21',
    borderRadius: '10px',
    padding: '30px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)', // Subtelny cień
    margin: '0 20px', // Zapewnia odstęp po bokach na mniejszych ekranach
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#F7F7F8',
  borderRadius: '4px',
  marginBottom: '10px',
  '& .MuiInputBase-input': {
    color: '#000', // Kolor tekstu wewnątrz pola
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#9147FF',
  color: '#FFF',
  '&:hover': {
    backgroundColor: '#772CE8',
  },
  marginBottom: '20px',
  width: '100%', // Przycisk na pełną szerokość
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: '#26262C',
  margin: '5px 0',
  borderRadius: '5px',
}));

const UserChatHistory = () => {
  const [username, setUsername] = useState('');
  const [messages, setMessages] = useState([]);
  const [streamers, setStreamers] = useState([]);
  const [selectedStreamer, setSelectedStreamer] = useState(null);
  const [lastStreamer, setLastStreamer] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [emotes, setEmotes] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const messagesPerPage = 100;

  // Pobranie emotek streamera
  const fetchEmotes = (twitchId) => {
    getStreamerEmotes(twitchId)
      .then(emotesData => {
        setEmotes(emotesData); // Zapisujemy pobrane emotki
      })
      .catch(error => {
        console.error('Błąd podczas pobierania emotek:', error);
      });
  };

  // Zamiana słów na emotki
  const replaceEmotes = (message) => {
    return message.split(' ').map((word, index) => {
      if (emotes[word]) {
        return (
          <img
            key={index}
            src={emotes[word]}
            alt={word}
            style={{ height: '28px', marginRight: '5px' }}
          />
        );
      }
      return word + ' '; // Dodaj spację, aby zachować odstępy między słowami
    });
  };

  const handleSearch = () => {
    getChatHistory(username)
      .then(response => {
        const userMessages = response.data;
        const sortedMessages = [...userMessages].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

        if (sortedMessages.length > 0) {
          setLastStreamer(sortedMessages[0].streamer);
        }

        const groupedByStreamers = userMessages.reduce((acc, message) => {
          const { streamer } = message;
          if (!acc[streamer]) {
            acc[streamer] = [];
          }
          acc[streamer].push(message);
          return acc;
        }, {});

        setMessages(userMessages);
        setStreamers(Object.keys(groupedByStreamers));
        setSelectedStreamer(null);
        setSuggestions([]);
        setCurrentPage(1); // Resetuj stronę po wyszukiwaniu
      })
      .catch(error => {
        console.error('Błąd podczas pobierania historii czatu:', error);
      });
  };

  const handleAutocomplete = (e) => {
    const input = e.target.value;
    setUsername(input);

    if (input.length > 2) { 
      getUserSuggestions(input)
        .then(response => {
          setSuggestions(response.data);
        })
        .catch(error => {
          console.error('Błąd podczas pobierania podpowiedzi:', error);
        });
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setUsername(suggestion);
    setSuggestions([]);
  };

  const handleStreamerClick = (streamer) => {
    setSelectedStreamer(streamer);
    fetchEmotes(streamer); // Pobieramy emotki streamera
    setCurrentPage(1); // Resetuj stronę przy zmianie streamera
  };

  // Oblicz wiadomości dla bieżącej strony
  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = messages
    .filter(msg => msg.streamer === selectedStreamer)
    .slice(indexOfFirstMessage, indexOfLastMessage);

  // Funkcje do zmiany strony
  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  return (
    <FullScreenWrapper>
      <StyledContainer>
        <Typography variant="h4" style={{ marginBottom: '20px', color: '#9147FF' }}>
          PLTW - Historia Polskiego Twitcha
        </Typography>
        
        <StyledTextField
          fullWidth
          label="Nazwa użytkownika"
          value={username}
          onChange={handleAutocomplete}
          autoComplete="off"
          variant="outlined"
        />
        
        <StyledButton variant="contained" onClick={handleSearch}>
          Szukaj
        </StyledButton>

        {/* Wyświetlanie podpowiedzi */}
        {suggestions.length > 0 && (
          <Paper style={{ width: '100%', maxHeight: '150px', overflowY: 'auto' }}>
            <List>
              {suggestions.map((suggestion, index) => (
                <StyledListItem key={index} button onClick={() => handleSuggestionClick(suggestion)}>
                  <ListItemText primary={suggestion} />
                </StyledListItem>
              ))}
            </List>
          </Paper>
        )}

        {/* Ostatnio widziany streamer */}
        {lastStreamer && !selectedStreamer && (
          <Typography variant="h6" style={{ marginTop: '20px', color: '#F7F7F8' }}>
            Ostatnio widziany u: {lastStreamer}
          </Typography>
        )}

        {/* Lista streamerów */}
        {!selectedStreamer && streamers.length > 0 && (
          <>
            <Typography variant="h6" style={{ marginTop: '20px', color: '#F7F7F8' }}>
              Streamerzy, których oglądał {username}:
            </Typography>
            <List style={{ width: '100%' }}>
              {streamers.map((streamer, index) => (
                <StyledListItem key={index} button onClick={() => handleStreamerClick(streamer)}>
                  <ListItemText primary={streamer} style={{ color: '#F7F7F8' }} />
                </StyledListItem>
              ))}
            </List>
          </>
        )}

        {/* Historia czatu */}
        {selectedStreamer && (
          <>
            <Typography variant="h6" style={{ marginTop: '20px', color: '#F7F7F8' }}>
              Historia czatu {username} u streamera {selectedStreamer}
            </Typography>
            <Paper style={{ width: '100%', backgroundColor: '#26262C', padding: '10px', marginTop: '10px' }}>
              <List>
                {currentMessages.map((msg, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={replaceEmotes(msg.message)} // Zamiana fraz na emotki
                      secondary={`${msg.user} - ${new Date(msg.timestamp).toLocaleString()}`}
                      style={{ color: '#F7F7F8' }}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
            <Box display="flex" justifyContent="space-between" marginTop="10px">
              <StyledButton variant="contained" onClick={handlePreviousPage} disabled={currentPage === 1}>
                Poprzednia
              </StyledButton>
              <StyledButton variant="contained" onClick={handleNextPage} disabled={indexOfLastMessage >= messages.length}>
                Następna
              </StyledButton>
            </Box>
            <StyledButton variant="contained" onClick={() => setSelectedStreamer(null)}>
              Wróć do listy streamerów
            </StyledButton>
          </>
        )}
      </StyledContainer>
    </FullScreenWrapper>
  );
};

export default UserChatHistory;

import axios from 'axios';

const API_URL = 'https://api.twitch.tv/helix'; // URL do Twitch API
const CLIENT_ID = 'pvht80jqa4bpmovuy7ch2tyc155qx2'; // Twój Client ID z Twitcha
const ACCESS_TOKEN = 'z5jyqzf2o3wms5ytx05u6h5d9m9dqm'; // Twój Access Token z Twitcha

// Funkcja do pobierania historii czatu użytkownika
export const getChatHistory = (username) => {
  return axios.get(`https://api.pltw.pl/user/${username}`);
};

// Funkcja do pobierania podpowiedzi użytkowników
export const getUserSuggestions = (query) => {
  return axios.get(`https://api.pltw.pl/autocomplete/${query}`);
};

// Funkcja do pobierania ID użytkownika z Twitcha
export const getTwitchUserId = async (username) => {
  try {
    const response = await axios.get(`${API_URL}/users`, {
      params: { login: username },
      headers: {
        'Client-ID': CLIENT_ID,
        Authorization: `Bearer ${ACCESS_TOKEN}`
      }
    });
    const userData = response.data.data[0];
    if (!userData) {
      throw new Error(`Nie znaleziono użytkownika ${username}`);
    }
    return userData.id; // Zwracamy ID użytkownika
  } catch (error) {
    console.error('Błąd podczas pobierania ID użytkownika z Twitcha:', error);
    throw error;
  }
};

// Funkcja do pobierania emotek dla streamera z 7TV
export const getStreamerEmotes = async (username) => {
  try {
    // Najpierw pobieramy ID użytkownika z Twitcha
    const twitchId = await getTwitchUserId(username);
    
    // Pobieramy emotki streamera z 7TV
    const response = await axios.get(`https://7tv.io/v3/users/twitch/${twitchId}`);
    const emotes = response.data.emote_set.emotes.reduce((acc, emote) => {
      acc[emote.name] = `https://cdn.7tv.app/emote/${emote.id}/1x.webp`;
      return acc;
    }, {});
    
    return emotes;
  } catch (error) {
    console.error('Błąd podczas pobierania emotek z 7TV:', error);
    throw error;
  }
};

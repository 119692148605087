import React from 'react';
import { Container, Paper, Box } from '@mui/material';
import UserChatHistory from './components/UserChatHistory';
import Footer from './Footer'; // Dodaj import stopki

const App = () => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Container component="main" sx={{ flex: 1 }}>
        <Paper>
          <UserChatHistory />
        </Paper>
      </Container>
      <Footer /> {/* Dodaj stopkę na dole */}
    </Box>
  );
};

export default App;

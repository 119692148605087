import React from 'react';
import './Footer.css'; // Import stylów CSS

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 PLTW.PL. Strona nie jest powiązana z firmą Twitch.</p>
        <ul className="footer-links">
          <li><a href="/politykaprywatnosci.html">Polityka Prywatności</a></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
